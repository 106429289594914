<template>
  <div class="dialog-wrap">
    <el-dialog
      :title="$t('dateRuleDialog.TimeTitle')"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :before-close="handleClose" 
      custom-class="dialog_tab"
      width="720px"
    >
      <div class="box">
        <el-scrollbar style="height:100%">

            <el-table
              :data='tableData'
              style="width: 98%"
              border
            >
              <el-table-column
                fixed
                prop="type"
                label=""
                width="110">
              </el-table-column>
              <el-table-column
                fixed
                prop="case"
                :label="$t('dateRuleDialog.RegularSymbol')"
                width="170">
              </el-table-column>
              <el-table-column
                fixed
                prop="cont"
               :label="$t('dateRuleDialog.Outputeffect')">
              </el-table-column>
            </el-table>
            <div class="note">{{$t('dateRuleDialog.note')}}</div>
            <div class="note-title"><span class="left">{{$t('dateRuleDialog.ConfigurationExample')}}</span>  <span class="right">{{$t('dateRuleDialog.currentTime')}}:2020-10-15 15:25:18</span></div>
            <el-table
              :data='tableData2'
              border
              style="width: 98%"
            >
              <el-table-column
                fixed
                prop="type"
                :label="$t('dateRuleDialog.ConfigurationExample')"
                width="325">
              </el-table-column>
              <el-table-column
                fixed
                prop="case"
                :label="$t('dateRuleDialog.Outputeffect')">
              </el-table-column>
            </el-table>
        </el-scrollbar>

        </div>
         <span slot="footer" class="dialog-footer">
          <el-button class="base-color-btn" @click="handleClose">{{$t('button.Confirm')}}</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'dateRuleDialog', 
  props: {
    dialogVisible: {
        type: Boolean,
        required: true
    },
  },
  mounted() {
  },
  data() {
    return {
      tableData: [{
        type: this.$t('dateRuleDialog.Year'),
        case: 'YY',
        cont: `70 71 ... 29 30(${this.$t("dateRuleDialog.yearTips")})`
      },
      {
        type: '',
        case: 'YYYY',
        cont:'1970 1971 ... 2029 2030'
      },
      {
        type: this.$t('dateRuleDialog.Month'),
        case: 'M',
        cont:' 1 2 ... 11 12  '
      },
      {
        type: '',
        case: 'MM',
        cont: `01 02 ... 11 12(${this.$t("dateRuleDialog.yearTips")})`
      },
      // 一二 ... 十一 十二 January February... November December 12 .... 30 3
      {
        type: '',
        case: 'MMM',
        cont:'一二 ... 十一 十二'
      },
      {
        type: '',
        case: 'MMMM',
        cont:`${this.$t("dateRuleDialog.monthDemo")}`
      },
      {
        type: this.$t('dateRuleDialog.Day'),
        case: 'D',
        cont:'12 .... 30 31'
      },
      {
        type: '',
        case: 'dd',
        cont:'01 02 .... 30 31'
      },
      {
        type: this.$t('dateRuleDialog.Hour'),
        case: 'H',
        cont:'0 1 .... 22 23'
      },
      {
        type: '',
        case: 'HH',
        cont:'00 01 .... 22 23'
      },
      {
        type: '',
        case: 'h',
        cont: `1 2 ... 11 12(${this.$t("dateRuleDialog.monthDemo")})`
      },
      {
        type: '',
        case: 'hh',
        cont:'01 02 .... 11 12'
      },
      {
        type: this.$t('dateRuleDialog.Minute'),
        case: 'm',
        cont:'01 ... 58 59'
      },
      {
        type: '',
        case: 'mm',
        cont:'00 01 ... 58 59'
      },
      {
        type: this.$t('dateRuleDialog.Second'),
        case: 's',
        cont:'0 1 ... 58 59'
      },
      {
        type: '',
        case: 'ss',
        cont:'01 01 ... 58 59'
      }],
      tableData2: [{
        type: 'YYYYMMdd',
        case:'20221015'
      },
      {
        type: 'YY-MM-dd',
        case:'22-10-15'
      },
      {
        type: 'YYYY-MM-dd',
        case:'2022-10-15'
      },
      {
        type: 'YYYY年MM月dd日',
        case:'2020年10月10日'
      },
      {
        type: 'YYYYMMddHHmmss',
        case:'20221015152518'
      }]
    }
  },
  methods: {
    handleClose() {
      this.$emit('handleClose')
    },
  }
}
</script>
<style lang="scss" scoped>

.dialog-wrap{
  .box{
    height:600px;
  }
  // ::v-deep .el-scrollbar__wrap{
  //   overflow: hidden;
  // }
  ::v-deep .el-table th.el-table__cell>.cell{
    text-align: center !important;
  }
  ::v-deep .el-table__cell{
    border-right: 1px solid #E8E8E8 !important;
  }
  ::v-deep .el-dialog__body {
    text-align: center;
    padding: 24px 20px 24px 24px !important;
  }
  .note{
    width: 620px;
    height: 48px;
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    line-height: 18px;
    margin:16px 0 45px;
    text-align: left;
  }
  .note-title{
    margin-bottom: 18px;
    display: flex;
    height: 20px;
    margin-bottom: 16px;
    justify-content: space-between;
    padding-right: 20px;
    color: #000000;
  }
  .left{
    font-size: 500;
    font-weight: bold !important; 
  }
  ::v-deep .el-button{
    display: flex;
    width: 130px;
    display: block;
  }
  .dialog-footer{
    margin-top: 20px;
  }
  .base-color-btn{
    background: #28C76F !important; 
    color: #fff;
    margin: 20px auto!important;
  }
}
</style>