<template>
<div class="merchant-config">
  <div class="action">
    <el-button type="primary" size="small" @click="creatList"><i class="el-icon-plus" />{{$t('systemParameters.New')}}</el-button>
    <el-button type="danger" size="small" @click="batchDeleteRule" :disabled="!selection()"><i class="el-icon-delete" />{{$t('systemParameters.BatchDelete')}}</el-button>
  </div>
  <div class="title">{{$t('systemParameters.MerchantConfigDetail')}}</div> 
  <el-table
    ref="normalTable"
    style="width: 100%;margin-bottom: 20px;text-align:center"
    :data="ruleList"
    @selection-change="handleSelectionChange"
    >
    <el-table-column
      type="selection"
      width="55"
      fixed>
    </el-table-column>
    <el-table-column 
      prop="scenarioName"
      :label="$t('systemParameters.Scenarios')"
      center
      >
    </el-table-column>
    <el-table-column 
      prop="columnName"
      :label="$t('systemParameters.QueryFields')"
      >
    </el-table-column>
    <el-table-column 
      prop="updateTime"
      :label="$t('systemParameters.UpdateTime')"
      >
    </el-table-column>
    <el-table-column
      fixed="right"
      :label="$t('systemParameters.Action')"
      width="200">
      <template slot-scope="scope">
        <el-button @click="deleteDetail(scope.row)" type="text" size="small">{{$t('systemParameters.Delete')}}</el-button>
        <el-button @click="editRule(scope.row)"  type="text" size="small">{{$t('systemParameters.Edit')}}</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog
    class="rule-dialog"
    :visible.sync="ruleDialog"
    :close-on-click-modal="false"
    width="500px"
    :title="$t('systemParameters.ProductQueryConfig')"
    :before-close="closeRuleDialog" 
  >
    <el-form ref="form" :model="formData" :rules="rules">
      <el-form-item :label="$t('systemParameters.Scenarios')" prop="scenarioId">
        <el-select size="small" style="width: 300px" v-model="formData.scenarioId" :disabled="isEdit">
          <el-option
            v-for="item in sceneList"
            :key="item.scenarioId"
            :label="item.translateTextName"
            :value="item.scenarioId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('systemParameters.QueryFields')" class="query-box" prop="scenarioFieldList">
        <el-input 
          size="small" 
          style="width: 300px" 
          v-model="formData.scenarioFieldNameList"
          suffix-icon="el-icon-more"
          readonly
          clearable
          :disabled="formData.scenarioId === ''"
          ></el-input> 
        <div v-if="formData.scenarioId" class="query" @click="choseFiled"></div>
      </el-form-item>
      <el-form-item class="flex">
        <el-button class="base-color-btn el-button--success" @click="save" >{{$t('button.Save')}}</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>

  <!-- 选择场景字段 -->
  <selectScenarioFields ref="selectScenarioFields" @onSelectConfirm="selectFieldsConfirm" :limitFiled="limit"></selectScenarioFields>

   <!-- 删除弹窗确认 -->
  <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="deleteConfirm"></deleteConfirmDialog>
</div>
</template>
<script>
import { getRuleList,getScenarioSimplifyList,ruleCreate,ruleDetail,batchDelete,ruleUpdate } from '@/libs/api/system-parameters'
import selectScenarioFields from "@/components/select-scenario-fields";
import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'
export default {
  components: {
    selectScenarioFields,
    deleteConfirmDialog
  },
  data() {
    return {
      limit: true,
      ruleList: [],
      ruleDialog: false,
      formData: {
        scenarioId: '',
        scenarioName: '',
        scenarioFieldList: '',
        scenarioFieldNameList: '',
        ruleId: ''
      },
      initFormData: {
        scenarioId: '',
        scenarioName: '',
        scenarioFieldList: '',
        scenarioFieldNameList: '',
        ruleId: ''
      },
      deleteId: '',
      sceneList: [],
      choseDialog: false,
      rules: {
        scenarioId: [
          { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' }
        ], 
        scenarioFieldList: [
          { required: true, message: this.$t('formValidate.required fields'), trigger: 'change' }
        ], 
      },
      multipleSelection: [],
      isEdit: false
    }
  },
  props: {
    // scenarioId: {
    //   default: String
    // }
  },
  mounted() {
    this.getList()
  },
  methods:{
    // 获取选中的数量
    selection() {
      return this.$refs.normalTable && this.$refs.normalTable.selection.length
    },
    handleSelectionChange(val) {
      console.log('val=====', val)
      this.multipleSelection = val
    },
    async getList() {
      const res = await getRuleList({ruleType: 1})
      console.log('res=====', res)
      this.ruleList = res.data?.data
    },
    // 批量删除
    batchDeleteRule() {
      this.$refs.deleteConfirmDialog.open()
      this.deleteId = this.multipleSelection.map(item => item.id).join(',')
    },
    // 删除场景规则
    deleteDetail(row) {
      this.$refs.deleteConfirmDialog.open()
      this.deleteId = row.id
    },
    deleteConfirm() {
      batchDelete({ruleIds: this.deleteId}).then(res => {
        if (res.status === 200 && res?.data?.code === 1 ) {
          this.$message.success(this.$t('systemParameters.DeleteSuccess'))
          this.getList()
        } else {
          this.$message.warning(res.message)
        }
        this.$refs.deleteConfirmDialog.close()
      })
    },
    // 编辑场景规则
    async editRule(row) {
      console.log('row====', row)
      this.isEdit = true
      this.ruleDialog = true
      // const result = await ruleDetail({ruleId: row.id})
      // console.log('result====', result)
      this.getSceneList()
      this.formData = {
        scenarioId: row.scenarioId,
        scenarioName: row.scenarioName,
        scenarioFieldList: row.rule,
        scenarioFieldNameList: row.columnName,
        ruleId: row.id
      }
    },
    closeRuleDialog() {
      this.$refs.form.resetFields()
      this.ruleDialog = false
    },
    // 创建场景规则
    creatList() {
      this.isEdit = false
      this.ruleDialog = true
      let obj = {...this.initFormData}
      this.formData = obj
      this.getSceneList()
      this.$refs.form && this.$refs.form.resetFields()
    },
    async getSceneList() {
      const params = {
        textKey:"",
        scenarioKey: "",
        isEffect: 0,
        agentId: ""
      }
      const res = await getScenarioSimplifyList(params)
      this.sceneList = res.data?.data;
      console.log('sceneList',this.sceneList)
    },
    choseFiled() {
      console.log('scenarioId',this.formData.scenarioId)
      this.choseDialog = true
      this.$refs.selectScenarioFields.open(this.formData.scenarioId,this.formData.scenarioFieldList)
    },
    selectFieldsConfirm(arr) {
      console.log('arr-------',arr)
      let temp = arr.map(item => item && item.translateTextName).join(',')
      console.log('已经选择的场景字段',temp)
      this.formData.scenarioFieldNameList = temp
      this.formData.scenarioFieldList = arr.map(item => item && item.columnName).join(',')
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.isEdit) {
            const params = {
              rule: this.formData.scenarioFieldList,
              ruleId:  this.formData.ruleId
            }
            ruleUpdate(params).then(res => {
              if (res.status === 200 && res?.data?.code === 1 ){
                console.log('res=====', res)
                this.$message.success(this.$t('systemParameters.SaveSuccess'))
              } else {
                this.$message.warning(res.data.message)
              }
              this.ruleDialog = false
              this.getList()
            })
          } else {
            const params = {
              scenarioId: this.formData.scenarioId,
              rule: this.formData.scenarioFieldList,
              ruleType: 1
            }
            ruleCreate(params).then(res => {
              if (res.status === 200 && res?.data?.code === 1 ){
                console.log('res=====', res)
                this.$message.success(this.$t('systemParameters.SaveSuccess'))
              } else {
                this.$message.warning(res.data.message)
              }
              this.ruleDialog = false
              this.getList()
            })
          }
          
        }
      })
    }
    
  }
}
</script>
<style lang="scss" scoped>
.merchant-config{
  .create-btn{
    background: #3461FF;
    color: #fff;
    border-radius: 5px;
  }
  .delete-btn{
    background: #FC174D;
    color: #fff;
    border-radius: 5px;
  }
  .action{
    display: flex;
    padding: 20px 0 0 20px;
  }
  .title{
    padding: 20px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    text-align: center;
  }
  ::v-deep .el-table .cell{
    text-align: center;
  }
  ::v-deep .el-form-item{
    display: flex;
    .el-form-item__label{
      width: 100px;
      text-align: left;
    }
  }
  .query-box{
    position: relative;
    .query{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
      z-index: 2;
    }
  }
  .flex{
    justify-content: center;
  }
  
}
</style>