<template>
  <div class="wrap">
    <div class="public-breadcrumb">
      {{$t('systemParameters.Welcome')}} {{ '>' }}{{$t('systemParameters.SystemConfiguration')}}> 
      <!-- <span v-if="activeIndex === 0" style="color:#3461FF">{{$t('ESL Type Selection')}}</span>
      <span v-if="activeIndex === 1" style="color:#3461FF">{{$t('Date Formatter')}}</span> -->
      <span style="color:#3461FF">{{$t('systemParameters.SystemParameters')}}</span>
    </div>
    <div class="main">
      <div class="menu">
        <span :class="itemMenu.showColor? 'item base-color' : 'item'" v-for="(itemMenu, indexMenu) in menuList" :key="indexMenu" @click="handleMenu(itemMenu)">
          {{itemMenu.name}}
        </span>
      </div>
      <div class="main-content">
        <div class="price-cont" v-if="activeIndex === 0"> 
          <div class="title">{{$t('systemParameters.ESLTypeSelection')}}（{{$t('systemParameters.SelectESLTitle')}}）</div>
          <el-divider />
          <div class="cont-all" v-if="userType === 203">
              <el-checkbox-group v-model="typeIdList" @change="handleCheck" >
                <!-- <el-scrollbar style="height:100%"> -->
                  <el-checkbox v-for="(itemType,indexType) in checkList" :key="indexType" :label="itemType.tagTypeCodeId">{{ itemType.typeName }}</el-checkbox>
                <!-- </el-scrollbar> -->
              </el-checkbox-group>
            <el-button v-if="checkList.length > 0" class="base-color-btn" @click="savePriceType">{{$t('button.Save')}}</el-button>
          </div>
          <div class="cont" v-else>
            <el-table
              style="width: 100%;margin-bottom: 20px;"
             :data="priceList"
            >
              <el-table-column
                :label="$t('systemParameters.CustomerName')"
                sortable
                prop="merchantName"
              >
                <el-table-column prop="merchantName" width="305">
                  <template slot="header" slot-scope="scope">
                    <el-input size="small" style="width:130px" v-model="merchantInfo.merchantName" @input="getMerchantList" placeholder=""></el-input>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                :label="$t('systemParameters.CustomerCode')"
                prop="merchantCode"
                sortable
              >
                <el-table-column prop="merchantCode" width="205">
                  <template slot="header" slot-scope="scope">
                    <el-input  size="small" style="width: 130px" v-model="merchantInfo.merchantCode" @input="getMerchantList" placeholder=""></el-input>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                sortable
                prop="updateTime"
                :label="$t('systemParameters.UpdateTime')"
                align="center"
                width="355"
              >

                <el-table-column prop="updateTime" align="center" min-width="285">
                  <template slot="header" slot-scope="scope">
                    <el-date-picker
                      size="small"
                      style="min-width: 240px"
                      clearable
                      v-model="merchantInfo.startEndDate"
                      type="daterange"
                      placement="bottom-start"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      @input="getMerchantList"
                    />
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                prop=""
                :label="$t('systemParameters.ESLType')"
                width="55"
              >
                <el-table-column prop="eslType" width="205">
                  <template slot-scope="scope" >
                    <div style="cursor:pointer" @click="handleItemCheck(scope)"><i class="el-icon-more"></i></div>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
             <!-- <div class="d-flex justify-content-between flex-wrap b_pag">
                <div class="d-flex align-items-center">
                  <span class="text-nowrap">
                    Display
                  </span>
                  <el-select v-model="merchantInfo.size" @change="getMerchantList" placeholder="">
                    <el-option
                      v-for="item in [10, 20 , 50, 100,200,500, 1000]"
                      :key="item"
                      :label="item"
                      :value="item">
                    </el-option>
                  </el-select>
                  <span class="text-nowrap">Strip</span>
                </div>
                <el-pagination
                  background
                  @size-change="getMerchantList"
                  @current-change="getMerchantList"
                  :page-size.sync="merchantInfo.size"
                  :current-page.sync="merchantInfo.current"
                  prev-text="Previous"
                  next-text="Next"
                  layout="prev, pager, next"
                  :total="merchantInfo.total"
                >
                </el-pagination>
             </div> -->
             <pagination :tableInfo="merchantInfo" @changePage="changePage" />
          </div>
        </div>
        <div class="date-cont" v-if="activeIndex === 1">
          <div class="title">{{$t ('systemParameters.DateFormatter')}}</div>
          <el-divider />
          <div class="cont">
            <el-form ref="form" :model="form" >
              <el-form-item :label="$t('systemParameters.TimeZone')">
                <el-input size="small" v-model="form.timeZone"></el-input> 
              </el-form-item>
              <el-form-item :label="$t('systemParameters.ALL')">
                <el-input size="small" v-model="form.allFormat"></el-input> 
                <!-- <i class="el-icon-warning-outline" @click="dialogVisible = true"></i> -->
                <img style="cursor:pointer" @click="dialogVisible = true" src="@/assets/img/date-detail.png" alt="">
              </el-form-item>
              <el-form-item :label="$t('systemParameters.YearMonthDay')">
                <el-input size="small" v-model="form.yearFormat"></el-input>
              </el-form-item>
              <el-form-item :label="$t('systemParameters.HourMinuteSecond')">
                <el-input size="small" v-model="form.dayFormat"></el-input>
              </el-form-item>
              <el-button class="base-color-btn" @click="saveTime">{{$t('button.Save')}}</el-button>
            </el-form>
          </div>
        </div>
        <merchant-config v-if="activeIndex === 2" />
        <barcode-config v-if="activeIndex === 3"/>
      </div>
    </div>
    <date-rule-dialog :dialogVisible="dialogVisible" @handleClose="handleClose" />
    <el-dialog
      class="tag-dialog"
      :visible.sync="tagDialog"
      :close-on-click-modal="false"
       width="600px"
       :title="$t('systemParameters.ESLTypeSelect')"
    >
    <div style="">
      <el-scrollbar style="height: 100%">
        <div class="tag-cont">
          <el-checkbox-group v-model="tagItemIdList">
            <el-checkbox v-for="(itemTag,indexTag) in itemCheckList" :key="indexTag" :label="itemTag.tagTypeCodeId">{{ itemTag.typeName }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tagDialog = false" style="background: #D4D4D4;">{{$t('button.Cancel')}}</el-button>
        <el-button class="base-color-btn" @click="saveTagDialog()" >{{$t('button.Save')}}</el-button>
      </span>
    </div>
      
    </el-dialog>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import { getTimeConfig,saveTimeConfig,updateTimeConfig, getAllTagType,saveTagtype, getMerchant } from '@/libs/api/system-parameters'
import dateRuleDialog from '@/components/date-rule-dialog'
import pagination from '@/components/pagination'
import { commonStore } from "@/store/global-select-store";
import MerchantConfig from './merchant-config'
import BarcodeConfig from './barcode-config'
export default {
  components: {dateRuleDialog,pagination,MerchantConfig,BarcodeConfig},
  data() {
    return {
      commonStore,
      activeIndex: 0,
      menuList:[],
      // menuList: [
      //   {name: 'ESL Type Selection', id: 0, showColor: true},
      //   {name: 'Date Formatter', id: 1, showColor: false},
      // ],
      checkList: [],
      typeIdList: [],
      form: {
        timeZone:'',
        allFormat: '',
        yearFormat: '',
        dayFormat: '',
        dateConfigId: ''
      },
      dialogVisible: false,
      priceList: [],
      tagDialog: false,
      tagItemIdList: [],
      itemCheckList: [],
      merchantInfo:{
        merchantName: '',
        merchantCode: '',
        startEndDate: [],
        current: 1,
        size: 10,
        total: 0,
        pages: 1
      },
      checkedMerchantId: '',//sessionStorage.getItem("userType")
      userType: (JSON.parse(localStorage.getItem("userData") || {})).userType, // 201 经销商用户； 202 运维用户； 203 门店总部； 204 门店用户 205 超级管理员
      // userType: 203,
      merchantId: localStorage.getItem('merchantId')
    }
  },
  computed:{
    language() {
      return this.$store.state.appConfig.language
    }
  },
  watch: {
    language(val) {
      if(val) this.initPage()
    },
  },
  mounted() {
    console.log('更新=========')
    commonStore.setInjectCallBack(this.initPage)
    this.initPage()
  },
  methods: {
    initPage() {
      if (this.userType === 203) {
        this.menuList = [
          {name: this.$t('systemParameters.ESLTypeSelection'), id: 0, showColor: true},
          {name: this.$t('systemParameters.DateFormatter'), id: 1, showColor: false},
          {name: this.$t('systemParameters.MerchantConfig'), id: 2, showColor: false},
          {name: this.$t('systemParameters.BarcodeInterceptionConfig'), id: 3, showColor: false},
        ]
      } else if(this.userType === 204 || this.userType === 205) {
        this.menuList = [
          {name: this.$t('systemParameters.DateFormatter'), id: 1, showColor: true},
        ]
        this.activeIndex = 1
      } else {
        this.menuList = [
          {name: this.$t('systemParameters.ESLTypeSelection'), id: 0, showColor: true},
          {name: this.$t('systemParameters.DateFormatter'), id: 1, showColor: false},
        ]
        this.activeIndex = 0
      }
      if (this.userType === 203) {
        this.getTypeList()
      } else if (this.userType === 204 || this.userType === 205) {
        this.getDateList()
      } else {
        this.getMerchantList()
      }
    },
    changePage() {
      this.getMerchantList()
    },
    handleMenu(item) {
      this.activeIndex = item.id
      this.menuList.forEach(t => {
        t.showColor = false
        if (t.id === item.id) {
          t.showColor = true
          if (item.id === 0) {
            // 价签
            if (this.userType === 203) {
              this.getTypeList()
            } else {
              this.getMerchantList()
            }
          } else if (item.id === 1){
            // 获取时间格式
            this.getDateList()
          }
        }
      });
    },
    getDateList() {
      let params
      if (this.userType === 203) {
        params = { merchantId: this.merchantId}
      } else {
        params = { storeId: localStorage.getItem('storeId')}
      }
      getTimeConfig(params).then(res => {
        if (res.status==200 && res?.data?.code === 1) {
          const data = res?.data?.data
          // console.log('res======', data)
          this.form.timeZone = data.timeZone
          this.form.allFormat = data.allFormat
          this.form.yearFormat = data.yearFormat
          this.form.dayFormat = data.dayFormat
          if (data.dateFormatConfigId) {
            this.form.dateConfigId = data.dateFormatConfigId
          }
        } else {
          this.$message({
            message: res.data.message,
            type: 'warning'
          });
        }
      })
    },
    valid(val1, val2, val3){
      if((val1 === '' && val2 === '' && val3 === '') || (val1 !== '' && val2 !== '' && val3 !== '')) {
        return true
      } else {
        return false
      }
    },
    // 保存-默认格式设置
    saveTime() { 
      if (!this.valid(this.form.timeZone,this.form.allFormat,this.form.yearFormat,this.form.dayFormat)) {
        this.$message.warning(this.$t('systemParameters.DateFormatLimitTips'))
        return
      }
      let params
      if (this.form.dateConfigId) {
        // 更新接口
        params = {
          timeZone: this.form.timeZone,
          allFormat: this.form.allFormat,
          yearFormat: this.form.yearFormat,
          dayFormat: this.form.dayFormat,
          dateConfigId: this.form.dateConfigId
        }
        updateTimeConfig(params).then(res => {
          if (res.status === 200 && res?.data?.code === 1 ) {
            console.log('saveTimeConfigres======', res)
            this.getDateList()
            this.$message.success(this.$t('systemParameters.SaveSuccess'))
          } else {
            this.$message({
              message: res.data.message,
              type: 'warning'
            });
          }
        })
      } else {
        // 保存接口
         params = {
          timeZone: this.form.timeZone,
          allFormat: this.form.allFormat,
          yearFormat: this.form.yearFormat,
          dayFormat: this.form.dayFormat,
        }
        this.userType === 203 ? params.merchantId = this.merchantId : params.storeId = localStorage.getItem('storeId')
        saveTimeConfig(params).then(res => {
          if (res.status === 200 && res?.data?.code === 1 ) {
            console.log('saveTimeConfigres======', res)
            this.getDateList()
            this.$message.success(this.$t('systemParameters.SaveSuccess'))
          } else {
            this.$message({
              message: res.data.message,
              type: 'warning'
            });
          }
        })
      }
      
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 客户 - 获取价签
    async getTypeList() {
      const res = await getAllTagType({merchantId: this.merchantId}).then()
      console.log('2222res========', res)
      if (res.status === 200 && res?.data?.code === 1) {
        this.typeIdList = []
        this.checkList = res?.data?.data
        this.checkList.forEach(item => {
          if (item.selected) {
            this.typeIdList.push(item.tagTypeCodeId)
          }
        })
        console.log('checkList======', this.checkList)
        console.log('typeIdList======', this.typeIdList)
      } else {
          this.$message({
            message: res.data.message,
            type: 'warning'
          });
        }
    },
    // 客户-选择
    handleCheck() {
      // this.checkList.forEach(item => {
      //   if (item.selected) {
      //     this.typeIdList.push(item.tagTypeCodeId)
      //   }
      // })
      console.log('typeIdList', this.typeIdList)
    },
    // 商户-保存-价签
    savePriceType() {
      if (this.typeIdList.length === 0) {
        // this.$message({
        //   message: 'Please Select',
        //   type: 'warning'
        // });
        this.$message.warning(this.$t('systemParameters.ESLTypeSelectTips'))
        return
      }
      const params = {
        merchantId: this.merchantId,
        tagTypeIds: this.typeIdList.join() || ''
      }
      saveTagtype(params).then(res => {
        if (res.status === 200 && res?.data?.code === 1) {
          this.$message.success(this.$t('systemParameters.SaveSuccess'))
        } else {
           this.$message({
            message: res.data.message,
            type: 'warning'
          });
        }
      })
    },
    // 运维 - 获取商户列表
    getMerchantList:debounce(function(){
      const params = {
        customerName: this.merchantInfo?.merchantName,
        customerCode: this.merchantInfo?.merchantCode,
        // startDate: this.merchantInfo?.startDate,
        // endDate: this.merchantInfo?.endDate,
        startDate: this.merchantInfo?.startEndDate?.[0] || '',
        endDate: this.merchantInfo?.startEndDate?.[1] || '',
        current: this.merchantInfo?.current,
        size: this.merchantInfo?.size
      }
      console.log('params======', params)
      getMerchant(params).then(res => {
        if (res.status === 200 && res?.data.code === 1) {
          const data = res?.data?.data
          this.priceList = data?.records
          this.merchantInfo.total = data?.total
        } else {
          this.$message({
            message: res.data.message,
            type: 'warning'
          });
        }
        console.log('获取商户列表=======', res)
      })
    }, 1000),
    // 运维-选择
    async handleItemCheck(val) {
      console.log('val========', val)
      this.checkedMerchantId = val.row.merchantId
      this.tagDialog = true
      const res = await getAllTagType({merchantId: this.checkedMerchantId}).then()
      console.log('2222res========', res)
      if (res.status === 200 && res?.data.code === 1) {
        this.tagItemIdList = []
        this.itemCheckList = res?.data?.data
        console.log('3333333itemCheckList========', this.itemCheckList)

        this.itemCheckList.forEach(item => {
          if (item.selected) {
            this.tagItemIdList.push(item.tagTypeCodeId)
          }
        })
        console.log('tagItemIdList值========', this.tagItemIdList)
      } else {
        this.$message({
            message: res.data.message,
            type: 'warning'
          });
      }
    },
    // 保存 - 运维选择价签
    saveTagDialog() {
      if (this.tagItemIdList.length === 0) {
        // this.$message({
        //   message: 'Please Select',
        //   type: 'warning'
        // });
        this.$message.warning(this.$t('systemParameters.ESLTypeSelectTips'))
        return
      }
      console.log('val========', this.checkedMerchantId, this.tagItemIdList, this.tagItemIdList.join(','))
      const params = {
        merchantId: this.checkedMerchantId,
        tagTypeIds: this.tagItemIdList.join(',') || '',
        orders:''
      }
      this.tagDialog = false
      saveTagtype(params).then(res => {
        if (res.status === 200 && res?.data.code === 1) {
          this.$message.success(this.$t('systemParameters.SaveSuccess'))
        } else {
          this.$message({
              message: res.data.message,
              type: 'warning'
            });
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.base-color {
  background: #D3FFE7 !important;
}
.base-color-btn{
  background: #28C76F !important; 
  color: #fff;
}
.wrap { 
  .txtalein{
    height: 20px;
    margin-bottom: 20px;
  }
  .main{
    display: flex;
    .menu{
      width: 300px;
      height: 650px;
      background: #fff;
      margin-right: 30px;
      padding: 20px;
      border-radius: 6px;
      .item{
        display: inline-block;
        width: 256px;
        height: 40px;
        background: #EDEDED;
        border-radius: 6px;
        padding-left: 20px;
        line-height: 40px;
        margin-bottom: 9px;
        cursor: pointer;
      }
    }
    .main-content{
      flex: 1;
      width: calc(100% - 240px);
      height: 650px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);
      border-radius: 6px;
      // padding: 0 27px 0 32px;
      .price-cont{
        .title{
          height: 40px;
          line-height: 40px;
          padding-left: 20px;
        }
        ::v-deep .el-checkbox-group{
          width: 420px;
          margin: 74px auto;
          // height: 400px;
          // border: 1px solid #E6E5E5;
        }
        ::v-deep .el-checkbox{
          width: 150px;
          margin-bottom: 30px;
        }
        ::v-deep .el-scrollbar__view {
          // text-align: center;
          padding-top: 40px;
        }
        ::v-deep .el-table__cell{
          text-align: center;
          height: 40px;
        }
        ::v-deep .el-table th.el-table__cell:nth-child(3)>.cell {
           display: flex;
           justify-content: center;
           align-items: center;
        }
        ::v-deep .el-table__cell:first-child .cell{
          padding-left: 30px !important;
        }
        
        .cont-all{
          .header{
            height: 78px;
            line-height: 78px;
          }
          ::v-deep .cell{
            display: flex;
            justify-content: center;
             align-items: center;
          }
          ::v-deep .el-button{
            display: flex;
            margin: 0 auto!important;
            width: 130px;
            display: block;
          }
          //.list li:nth-of-type(odd)
          .el-checkbox-group .el-checkbox:nth-of-type(odd){
            margin-left: 40px;
          }
        }
      }
      .date-cont{
        .title{
           height: 40px;
          line-height: 40px;
          padding-left: 20px;
        }
        ::v-deep .el-button{
          display: flex;
          margin: 50px auto!important;
          width: 130px;
          display: block;
        }
        .cont{
          padding-top: 92px;
          img{
            width: 20px;
            height: 20px;
            display: inline-block;
            position: absolute;
            top: 10px;
            right: 5px;
          }
          ::v-deep .el-form {
            width:600px;
            margin: 0 auto !important;
          }
          ::v-deep .el-form-item{
            margin-bottom: 10px !important;
          }
          ::v-deep .el-form-item__content{
            display: flex;
            width: 400px;
            position: relative;
          }
          ::v-deep .el-input__inner{
            width:400px;
          }
          ::v-deep .el-form-item__label {
            min-width: 165px;
            color: #999999;
          }
          ::v-deep .el-icon-warning-outline {
            position: absolute;
            top: 7px;
            margin-left: 16px;
            font-size: 28px;
          }
        }

      }
    }
  }
  ::v-deep .el-divider--horizontal {
    margin: 0;
  }
  .tag-dialog{
    // color: hsl(220deg 3% 39%);
    .tag-cont{
      width: 280px;
      display: flex;
      margin-left: 180px;
      margin-top: 20px;
      .el-checkbox{
        width: 120px !important;
        height: 50px !important;
        margin-right: 0 !important;
      }
    }
    ::v-deep .el-dialog__header{
      // padding: 20px !important;
    }
    ::v-deep .el-dialog__body{
        height: 470px !important;;
      }
    ::v-deep .el-dialog__footer{
      display: flex;
    }
    ::v-deep .el-button{
      width: 120px;
    }
    .dialog-footer{
      position: absolute;
      bottom: 40px;
      left: 50%;
      margin-left: -125px;
    }
  }
}
::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{
  color:#333;
}
</style>