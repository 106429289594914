<template>
  <div class="barcode-config">
    <div class="action">
      <el-button type="primary" size="small" @click="creatList"><i class="el-icon-plus" />{{$t('systemParameters.New')}}</el-button>
      <el-button type="danger" size="small" @click="batchDeleteRule" :disabled="!selection()"><i class="el-icon-delete" />{{$t('systemParameters.BatchDelete')}}</el-button>
    </div>
    <div class="title">{{$t('systemParameters.BarcodeInterceptionConfig')}}</div> 
    <el-table
      ref="normalTable"
      style="width: 100%;margin-bottom: 20px;text-align:center"
      :data="ruleList"
      @selection-change="handleSelectionChange"
      >
      <el-table-column
        type="selection"
        width="55"
        fixed>
      </el-table-column>
      <el-table-column 
        prop="scenarioName"
        :label="$t('systemParameters.Scenarios')"
        center
        >
      </el-table-column>
      <el-table-column 
        prop="note"
        :label="$t('systemParameters.RuleDetail')"
        >
      </el-table-column>
      <el-table-column 
        prop="updateTime"
        :label="$t('systemParameters.UpdateTime')"
        >
      </el-table-column>
      <el-table-column
        fixed="right"
        :label="$t('systemParameters.Action')"
        width="200">
        <template slot-scope="scope">
          <el-button @click="deleteDetail(scope.row)" type="text" size="small">{{$t('systemParameters.Delete')}}</el-button>
          <el-button @click="editRule(scope.row)"  type="text" size="small">{{$t('systemParameters.Edit')}}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      class="rule-dialog"
      :visible.sync="ruleDialog"
      :close-on-click-modal="false"
      width="600px"
      :title="$t('systemParameters.FieldInterceptionConfig')"
      :before-close="closeRuleDialog" 
    >
      <el-form ref="form" :model="formData" :rules="rules">
        <el-form-item :label="$t('systemParameters.Scenarios')" prop="scenarioId">
          <el-select size="small" style="width: 300px" v-model="formData.scenarioId" :disabled="isEdit">
            <el-option
              v-for="item in sceneList"
              :key="item.scenarioId"
              :label="item.translateTextName"
              :value="item.scenarioId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('systemParameters.RuleDetail')" class="query-box" prop="note" style="marign-left:8px">
          <el-input 
            size="small" 
            style="width: 300px" 
            v-model="formData.note"
            placeholder=""
            clearable
            />
        </el-form-item>
        <el-form-item :label="$t('systemParameters.RuleCode')" class="query-box" prop="rule">
          <el-input 
            size="small" 
            type="textarea"
            style="width: 300px" 
            v-model="formData.rule"
            suffix-icon="el-icon-more"
            placeholder=""
            clearable
            />
        </el-form-item>
        <el-form-item class="flex">
          <el-button class="base-color-btn el-button--success" @click="save" >{{$t('button.Save')}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

     <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="deleteConfirm"></deleteConfirmDialog>
  </div>
</template>
<script>
import { getRuleList,getScenarioSimplifyList,ruleCreate,batchDelete,ruleUpdate } from '@/libs/api/system-parameters'
import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'

export default {
  components: {
    deleteConfirmDialog
  },
  data() {
    return {
      limit: true,
      ruleList: [],
      ruleDialog: false,
      formData: {
        scenarioId: '',
        note: '',
        rule: '',
        ruleId: ''
      },
      initFormData: {
        scenarioId: '',
        note: '',
        rule: '',
        ruleId: ''
      },
      deleteId: '',
      sceneList: [],
      choseDialog: false,
      rules: {
        scenarioId: [
          { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' }
        ], 
        note: [
          { required: false, message: this.$t('formValidate.maxLength'), trigger: 'change', max: 100 }
        ], 
        rule: [
          { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur'}
        ],
      },
      multipleSelection: [],
      isEdit: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 获取选中的数量
    selection() {
      return this.$refs.normalTable && this.$refs.normalTable.selection.length
    },
    handleSelectionChange(val) {
      console.log('val=====', val)
      this.multipleSelection = val
    },
    async getList() {
      const res = await getRuleList({ruleType: 0})
      console.log('res=====', res)
      this.ruleList = res.data?.data
    },
    creatList() {
      this.isEdit = false
      this.ruleDialog = true
      let obj = {...this.initFormData}
      this.formData = obj
      this.getSceneList()
      this.$refs.form && this.$refs.form.resetFields()
    },
    async getSceneList() {
      const params = {
        textKey:"",
        scenarioKey: "",
        isEffect: 0,
        agentId: ""
      }
      const res = await getScenarioSimplifyList(params)
      this.sceneList = res.data?.data;
      console.log('sceneList',this.sceneList)
    },
    // 批量删除
    batchDeleteRule() {
      this.$refs.deleteConfirmDialog.open()
      this.deleteId = this.multipleSelection.map(item => item && item.id).join(',')
    },
    // 删除场景规则
    deleteDetail(row) {
      this.$refs.deleteConfirmDialog.open()
      this.deleteId = row.id
    },
    deleteConfirm() {
      batchDelete({ruleIds: this.deleteId}).then(res => {
        if (res.status === 200 && res?.data?.code === 1 ) {
          this.$message.success(this.$t('systemParameters.DeleteSuccess'))
          this.getList()
        } else {
          this.$message.warning(res.message)
        }
        this.$refs.deleteConfirmDialog.close()
      })
    },
    closeRuleDialog() {
      this.$refs.form.resetFields()
      this.ruleDialog = false
    },
    editRule(row) {
      this.isEdit = true
      console.log('row====', row)
      this.isEdit = true
      this.ruleDialog = true
      this.getSceneList()
      this.formData = {
        scenarioId: row.scenarioId,
        note: row.note,
        rule: row.rule,
        ruleId: row.id
      }
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.isEdit) {
            const params = {
              ruleId: this.formData.ruleId,
              note: this.formData.note,
              rule: this.formData.rule,
            }
            ruleUpdate(params).then(res => {
              if (res.status === 200 && res?.data?.code === 1) {
                console.log('res=====', res)
                this.$message.success(this.$t('systemParameters.SaveSuccess'))
              } else {
                console.log('res=====', res)
                this.$message.warning(res.data.message)
              }
              this.ruleDialog = false
              this.getList()
            })
          } else {
            const params = {
              scenarioId: this.formData.scenarioId,
              note: this.formData.note,
              rule: this.formData.rule,
              ruleType: 0
            }
            ruleCreate(params).then(res => {
              if (res.status === 200 && res?.data?.code === 1 ) {
                console.log('res=====', res)
                this.$message.success(this.$t('systemParameters.SaveSuccess'))
              } else {
                console.log('res=====', res)
                this.$message.warning(res.data.message)
              }
              this.ruleDialog = false
              this.getList()
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.barcode-config{
  .action{
      display: flex;
      padding: 20px 0 0 20px;
    }
    .title{
      padding: 20px;
    }
    ::v-deep .el-table th.el-table__cell>.cell{
      text-align: center;
    }
    ::v-deep .el-table .cell{
      text-align: center;
    }
    ::v-deep .el-form-item{
      display: flex;
      .el-form-item__label{
        width: 200px;
        text-align: left;
      }
    }
    ::v-deep .el-form-item .el-form-item__label {
      width: 200px;
    }
    .query-box{
      position: relative;
      .query{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
        z-index: 2;
      }
    }
    .flex{
      justify-content: center;
    }
}
</style>